<template>
  <div class="min-h-screen bg-slate">
    <div class="relative top-28 md:top-40 container m-auto">
      <div
        class="m-auto bg-white bx-sdw max-w-md p-5 py-10 lg:p-10 rounded maxw"
      >
        <h1 class="text-md font-medium mb-7">Admin Login</h1>
        <form action="" v-on:submit.prevent="login">
          <div class="mb-5">
            <label for="username">User name</label>
            <p>
              <input
                type="text"
                name="username"
                id="username"
                class="w-full p-3 border border-tetiary rounded mt-1"
                v-model="username"
              />
            </p>
          </div>
          <div class="mb-6">
            <label for="pswd">Password</label>
            <p>
              <input
                type="password"
                name="pswd"
                id="username"
                class="w-full p-3 border border-tetiary rounded mt-1"
                v-model="password"
              />
            </p>
          </div>
          <div>
            <button class="btn w-full" type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminLogin",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("adminLogin", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push("/admin");
        });
    },
  },
};
</script>